<template>
  <div>
    <div class="application_list_view" v-loading="loading">
      <div class="application_list_view_left">
        <div class="application_list_view_left_bg">
          <div class="application_list_view_left_view" @click="isShowWindow=true">
            <div class="application_list_view_left_list">
              <el-row :gutter="10">
                <el-col :span="4" :offset="0" v-for="(item,index)  in list" :key="index">
                  <div class="application_list_view_left_card">
                    <el-image :src="fileUrl+item.imgUrl" fit="fill" style="width:70px;height:70px"></el-image>
                    <p>{{item.name}}</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 350px;" v-show="!bodySize"></div>
      <div class="application_list_view_right" v-show="isShowWindow">
        <div class="application_list_view_right_btn">
          <div
            style="display:flex;justify-content:space-between;align-items:center;border-bottom: 1px solid #d7dae2;padding-bottom:10px;height:40px"
          >
            <i
              class="el-icon-arrow-right"
              style="font-weight:bold;cursor:pointer"
              @click="isShowWindow=!isShowWindow"
            ></i>
            <el-dropdown
              placement="bottom"
              trigger="click"
              @command="openSelected"
              v-if="isShowAddBtn"
            >
              <el-button type="primary" round size="small" style="width:100px;">新增应用</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="library"
                  style="padding:0"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                <el-dropdown-item
                  command="local"
                  style="padding:0;text-align:center"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div style="margin-top:10px">
            <el-radio-group v-model="apk_list_type_selected" @change="onSubmitEditApkListType">
              <el-radio-button label="private">专属列表</el-radio-button>
              <el-radio-button label="common">公共列表</el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div class="application_list_view_right_view">
          <div class="application_list_view_right_card" v-for="(item,index) in list" :key="index">
            <div class="application_list_view_right_card_btn">
              <div>
                <el-button
                  type="text"
                  size="mini"
                  icon="el-icon-top"
                  @click="upSortApplicationList(index)"
                  v-if="index !=0"
                >上移</el-button>
                <el-button
                  type="text"
                  size="mini"
                  icon="el-icon-bottom"
                  @click="downSortApplicationList(index)"
                  v-if="list.length-1 !=index"
                >下移</el-button>
              </div>
              <el-button
                type="text"
                size="mini"
                icon="el-icon-close"
                @click="onSubmitDelApplication(item)"
                v-if="isShowAddBtn"
              ></el-button>
            </div>

            <div class="application_list_view_right_card_content">
              <el-image :src="fileUrl+item.imgUrl" fit="fill" style="width:70px;height:70px"></el-image>
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 用户添加应用资源库 -->
    <el-dialog :visible.sync="dialogAddApplication" :close-on-click-modal="false" width="50%">
      <span slot="title">新增应用</span>
      <Application
        v-if="dialogAddApplication"
        :apk_list_type="apk_list_type_selected"
        :apkList="list"
        :groupId="placesInfo.groupId"
        :choice="true"
      ></Application>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddApplication=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddApplication">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 用户新增应用本地上传 -->
    <el-dialog :visible.sync="dialogAddLocalApplication" :close-on-click-modal="false" width="40%">
      <span slot="title">新增应用</span>
      <div class="uploadDialog">
        <el-upload
          ref="apk-upload"
          drag
          :action="actionUrlApk"
          accept=".apk"
          :on-success="handleSuccess"
          :before-upload="handleBefore"
          :headers="header"
          :show-file-list="true"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">只能上传apk文件，且不超过50M</div>
        </el-upload>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getCurrencyApi,
  editCurrencyApi,
  getApplicationListApi,
  addApplicationListApi,
  editApplicationListApi,
  delApplicationListApi,
  addUploadApplicationListApi
} from "../../api/data";
import Aes from "../../utils/aes";
import Application from "../../components/Application";
export default {
  components: {
    Application
  },
  data() {
    return {
      loading: true,
      bodySize: false, //当前窗口是否小于1024
      isShowWindow: true, //是否显示右侧操作窗口
      list: [],
      dialogAddApplication: false, //应用资源库窗口
      apk_list_type: "", //当前数据库中保存的模式
      apk_list_type_selected: "", //页面选中的模式
      dialogAddLocalApplication: false, //本地上传应用窗口
      isShowAddBtn: false //是否显示添加应用按钮
    };
  },
  watch: {
    apk_list_type_selected(newVal) {
      if (this.userInfo.userLevel == "admin") {
        if (newVal == "private") {
          this.isShowAddBtn = true;
        } else {
          this.isShowAddBtn = false;
        }
      } else {
        this.isShowAddBtn = true;
      }
    }
  },
  computed: {
    //登录账户信息
    userInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.userInfo));
    },
    //当前场所信息
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    actionUrlApk(){
      return this.$store.state.fileUrl + "/user_apk/upload";
    }
  },
  created() {
    this.initLoadListType();
    this.wathSize();
    if (this.userInfo.userLevel == "admin") {
      if (this.apk_list_type_selected == "private") {
        this.isShowAddBtn = true;
      }
    } else {
      this.isShowAddBtn = true;
    }
  },
  methods: {
    //查询应用列表默认模式
    initLoadListType() {
      getCurrencyApi({ groupId: this.placesInfo.groupId }).then(res => {
        this.loading = false;
        if (res.data.apk_list_type) {
          this.apk_list_type = res.data.apk_list_type;
          this.apk_list_type_selected = res.data.apk_list_type;
          this.initLoad(res.data.apk_list_type);
        }
      });
    },
    //切换模式后列表显示(废弃)
    switchApkListType(val) {
      this.apk_list_type_selected = val;
      this.initLoad(val);
    },
    //修改应用列表默认模式
    onSubmitEditApkListType() {
      editCurrencyApi({
        groupId: this.placesInfo.groupId,
        apk_list_type: this.apk_list_type_selected
      }).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoadListType();
        }
      });
    },
    //初始化应用列表
    initLoad(type) {
      if (type == "private") {
        //查询个人列表
        getApplicationListApi({ groupId: this.placesInfo.groupId }).then(
          res => {
            this.list = res.data;
          }
        );
      } else {
        //查询公共列表
        getApplicationListApi().then(res => {
          this.list = res.data;
        });
      }
    },
    //资源库添加应用列表
    onSubmitAddApplication() {
      if (this.$store.state.fileApkList.length > 0) {
        addApplicationListApi({ groupId: this.placesInfo.groupId,data: this.$store.state.fileApkList }).then(
          res => {
            if (res) {
              this.dialogAddApplication = false;
              this.$message.success(res.msg);
              this.$store.commit("setFileApkList", []);
              this.initLoad(this.apk_list_type_selected);
            }
          }
        );
      }
    },
    //修改应用列表排序
    onSubmitEditApplication(row) {
      editApplicationListApi({ groupId: this.placesInfo.groupId,id: row.id, sort: row.sort }).then(res => {
        if (res) {
          // this.$message.success(res.msg);
          this.initLoad(this.apk_list_type_selected);
        }
      });
    },
    //删除应用应用列表
    onSubmitDelApplication(row) {
      this.$confirm("此操作将永久删除该应用, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delApplicationListApi({ groupId: this.placesInfo.groupId,id: row.id}).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad(this.apk_list_type_selected);
          }
        });
      });
    },
    //本地上传新增应用列表
    onSubmitAddLocalApplication(obj) {
      obj.type = this.apk_list_type_selected;
      obj.groupId = this.placesInfo.groupId;
      addUploadApplicationListApi(obj).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad(this.apk_list_type_selected);
          this.dialogAddLocalApplication = false;
        }
      });
    },

    //添加上传APK
    //上传前验证判断
    handleBefore(file) {
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isLt50M) {
        this.$message.warning("上传APK大小不能超过 50MB!");
      }
      return isLt50M;
    },
    //上传成功
    handleSuccess(response) {
      const res = JSON.parse(Aes.decode(response));
      if (res.type == "success") {
        if (res.msg == "equal") {
          //查询到有相同的APK包提示是否覆盖
          this.$confirm("存在相同APP,是否覆盖?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            //确认覆盖就将返回的带APPid对象存入form中
            res.data.apkType = "我的";
            this.onSubmitAddLocalApplication(res.data);
          });
        } else {
          this.$message.success(res.msg);
          res.data.publicity = "on";
          res.data.apkType = "我的";
          this.onSubmitAddLocalApplication(res.data);
        }
      } else {
        this.$message.error(res.msg);
      }
      this.$refs["apk-upload"].clearFiles();
    },

    //打开资源库
    openSelected(command) {
      if (command == "library") {
        this.dialogAddApplication = true;
      } else {
        this.dialogAddLocalApplication = true;
      }
    },
    //上移
    upSortApplicationList(index) {
      if (index != 0) {
        this.list[index].sort = this.list[index].sort - 1;
        this.list[index - 1].sort = this.list[index - 1].sort + 1;
        this.onSubmitEditApplication(this.list[index]);
        this.onSubmitEditApplication(this.list[index - 1]);
      }
    },
    //下移
    downSortApplicationList(index) {
      if (this.list.length - 1 != index) {
        this.list[index].sort = this.list[index].sort + 1;
        this.list[index + 1].sort = this.list[index + 1].sort - 1;
        this.onSubmitEditApplication(this.list[index]);
        this.onSubmitEditApplication(this.list[index + 1]);
      }
    },
    //监听当前窗口大小调整占用右侧DIV
    wathSize() {
      if (document.body.clientWidth <= 1024) {
        this.bodySize = true;
      } else {
        this.bodySize = false;
      }
      window.addEventListener("resize", () => {
        if (document.body.clientWidth <= 1024) {
          this.bodySize = true;
        } else {
          this.bodySize = false;
        }
      });
    }
  }
};
</script>
<style lang='scss'>
.application_list_view {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: hidden;
}
.application_list_view_left {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .application_list_view_left_bg {
    background: url("../../assets/hotel_interface.png");
    background-size: cover;
    width: 100%;
    max-width: 810px;
    height: 532px;
    padding-left: 5px;
    padding-top: 4px;
  }
  .application_list_view_left_view {
    width: 100%;
    max-width: 802px;
    height: 458px;
    // border: 2px solid red;
    // border-radius: 6px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    background: url("../../assets/bg_interface.jpg") center no-repeat;
    background-size: cover;
    .application_list_view_left_list {
      position: relative;
      padding: 10px 20px;
      height: 458px;
      overflow-y: auto;
      .application_list_view_left_card {
        width: 112px;
        height: 114px;
        margin-bottom: 10px;
        text-align: center;
        background: rgba(127, 147, 177, 0.5);
        border: 1px solid rgba(255, 255, 255, 0.5);
        padding: 10px 20px;
        border-radius: 6px;
        img {
          border-radius: 6px;
        }
        p {
          margin-top: 5px;
          width: 70px;
          font-size: 12px;
          margin-top: 5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .application_list_view_left_list::-webkit-scrollbar {
      width: 6px;
    }
    .application_list_view_left_list:hover::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.8);
    }
    .application_list_view_left_list::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 6px;
    }
  }
}

.application_list_view_right {
  background: #ffffff;
  border-top: 1px solid #d7dae2;
  width: 20%;
  height: 100%;
  z-index: 9;
  min-width: 300px;
  position: absolute;
  top: 0;
  right: 0;

  .application_list_view_right_btn {
    text-align: center;
    border-bottom: 1px solid #d7dae2;
    padding: 10px;
  }
  .application_list_view_right_view {
    overflow-y: auto;
    height: 100%;
    .application_list_view_right_card {
      border: 1px solid #ebeef5;
      border-radius: 6px;
      margin: 10px;
      cursor: pointer;
      .application_list_view_right_card_btn {
        background: rgb(244, 244, 245);
        border-radius: 6px 6px 0 0;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
      }
      .application_list_view_right_card_content {
        display: flex;
        justify-content: space-between;
        padding-right: 100px;
        align-items: center;
        padding: 5px 100px 5px 5px;
      }
    }
    .application_list_view_right_card:hover {
      box-shadow: 0 2px 12px 0 #619aec8c;
    }
  }
  .application_list_view_right_view::-webkit-scrollbar {
    width: 6px;
  }
  .application_list_view_right_view:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .application_list_view_right_view::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }
}
.uploadDialog {
  text-align: center;
  padding: 50px;
}
</style>